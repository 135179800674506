<!-- 二、三级页面footer-->
<template>
  <div class="sfoot publicPage">
    <div class="content">
      <div class="item">
        <span>版权所有：贵溪市图书馆</span>
        <span>赣ICP备案：赣ICP备17008151号-1</span>
        <span>地址：贵溪市雄石东路16号</span>
      </div>
      <div class="item">
        <span>联系人：薛建国</span>
        <span>联系电话：13870192600 0701-7187677</span>
        <span>邮箱：jxgxtsg@163.com</span>
      </div>
      <div class="item">
        <span>访问次数：127768</span>
        <span>技术支持：上海鹏轩数据技术有限公司</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.sfoot {
  width: 100%;
  height: 100px;
  position: relative;
  align-items: center;
  background-color: rgb(21, 117, 196);
  color: #fff;
  font-size: 12px;
  position: absolute;
  bottom: 0px;
  .content {
    width: 1100px;
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      padding: 6px 0px;
      span {
        width: 33%;
      }
    }
  }
}
@media screen {
  @media (max-width: 600px) {
    .sfoot {
      position: relative;
      margin-top: 20px;
      height: 220px;
      font-size: 12px;
      .content {
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
</style>
