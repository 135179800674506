<!-- 二级页面-->
<template>
  <div class="secondPage publicPage">
    <div class="content">
      <div class="right">
        <div class="top">
          <ititle text="搜索结果"></ititle>
        </div>
        <div class="pageBox">
          <ul>
            <li v-for="page in pageList" :key="page.id" @click="pageClick(page)">
              <span class="page">>{{ page.title }}</span>
              <span class="time">{{ timefmt(page.write_time) }}</span>
            </li>
          </ul>
          <!-- <el-pagination
            @current-change="handleCurrentChange"
            :page-size="10"
            :current-page="currentPage"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination> -->
        </div>
      </div>
    </div>
    <sfoot></sfoot>
  </div>
</template>

<script>
import ititle from "../components/ititle.vue";
import sfoot from "./components/sfoot.vue";
import { get_title_article } from "../request/apis";
import dayjs from "dayjs";
export default {
  name: "",
  components: { ititle, sfoot },
  props: {},
  data() {
    return {
      total: 20,
      currentPage: 1,
      pageList: [],
      address: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    //切换页码
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    //时间格式化
    timefmt(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    //点击文章
    pageClick(page) {
      window.open(page.url, "_blank");
    },
    init() {
      let pageList = JSON.parse(sessionStorage.getItem("searchList"));
      this.pageList = pageList;
    },
  },
};
</script>

<style lang="less" scoped>
.secondPage {
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  box-sizing: border-box;
  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url("../assets/图书馆背景图片.jpg");
    filter: blur(6px);
  }
  .content {
    width: 1100px;
    display: flex;
    height: calc(100vh - 220px);
    .right {
      flex: 1;
      width: 0;
      background-color: #fff;
      box-sizing: border-box;
      padding: 0px 20px 10px 20px;
      flex-direction: column;
      /deep/ .ititle {
        padding: 0px;
        height: 40px;
        .text {
          font-size: 20px;
          font-weight: normal;
        }
        .line {
          height: 20px;
        }
      }
      .top {
        border-bottom: 1px solid #999;
        display: flex;
        align-items: center;
        .address {
          margin-left: auto;
          flex-shrink: 0;
        }
      }
      .pageBox {
        height: calc(100% - 42px);
        ul {
          height: calc(100% - 38px);
          overflow-y: auto;
          li {
            display: flex;
            padding: 12px 0px;
            border-bottom: 1px solid rgb(222, 221, 221);
            cursor: pointer;
            .time {
              margin-left: auto;
            }
            &:hover {
              .page {
                color: #f00;
              }
            }
          }
        }
        /deep/ .el-pagination {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>
