<!--  -->
<template>
  <div class="ititle">
    <span class="line"></span>
    <span class="text">{{ text }}</span>
    <span v-if="more" class="more" @click="$emit('moreClick')">更多>></span>
    <span v-if="moreTwo" class="more" @click="$emit('moreClick')">MORE+</span>
  </div>
</template>

<script>
export default {
  props: ["text", "more", "moreTwo"],
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.ititle {
  display: flex;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
  .line {
    display: inline-block;
    width: 5px;
    height: 40px;
    background-color: #2851c0;
    position: relative;
    &::before {
      content: "";
      height: 8px;
      width: 5px;
      background-color: #f5bb1b;
      position: absolute;
      top: 0px;
    }
  }
  .text {
    font-size: 28px;
    color: #000;
    margin-left: 10px;
    font-weight: bolder;
  }
  .more {
    margin-left: auto;
    font-size: 20px;
    color: #b78957;
    cursor: pointer;
  }
}
@media screen {
  @media (max-width: 600px) {
    .ititle {
      padding-left: 0px;
      height: 50px;
      .text {
        font-size: 20px;
      }
      .line {
        height: 25px;
      }
    }
  }
}
</style>
